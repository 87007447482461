<template>
  <v-card
    color="neutral"
    class="rounded-xl"
  >
    <v-data-table
      :headers="headers"
      :items="companyMembers"
      sort-by="calories"
      class="elevation-1 neutral"
    >
      <template #top>
        <v-toolbar
          flat
          rounded="xl"
          color="neutral"
        >
          <v-toolbar-title>{{ $t('Pages.Company.members') }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <!--          <CompanyInviteLink-->
          <!--            id="top-bar"-->
          <!--          />-->
          <v-dialog
            v-model="dialog"
            content-class="rounded-xl"
            max-width="500px"
          >
            <v-card
              color="neutral"
              class="rounded-xl"
            >
              <v-card-title>
                <span class="text-h5">Edit permissions</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-checkbox
                        v-model="editedItem.permissions"
                        color="accent"
                        label="Admin"
                        value="Admin"
                      />
                      <v-checkbox
                        v-model="editedItem.permissions"
                        color="accent"
                        label="User"
                        value="User"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  @click="close"
                  v-text="$t('Common.cancel')"
                />
                <v-btn
                  rounded
                  light
                  @click="save"
                  v-text="$t('Common.save')"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDelete"
            content-class="rounded-xl"
            max-width="500"
            @click="closeDelete"
          >
            <CustomDialog
              title-icon="delete"
              :title="$t('Pages.Company.remove_member')"
              :second-title="$t('Pages.Company.remove_member_text')"
              :action-button="{
                class: 'deleteAction',
                id: 'delete-member-confirm-btn',
                text: $t('Common.delete')
              }"
              :close-button="{
                id: 'delete-cancel-btn',
                text: $t('Common.cancel')
              }"
              @close-event="closeDelete"
              @perform-action="deleteItemConfirm"
            />
          </v-dialog>
        </v-toolbar>
        <v-divider
          class="mx-4"
        />
      </template>
      <template #item.actions="{ item }">
        <v-btn
          icon
          @click="viewUserProfile(item)"
        >
          <v-icon small>
            mdi-account
          </v-icon>
        </v-btn>
        <v-btn
          icon
          @click="deleteItem(item)"
        >
          <v-icon small>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <template #no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn>
      </template>
      <template #[`item.full_name`]="{ item }">
        {{ item.firstName }} {{ item.lastName }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
// import CompanyInviteLink from "@/pages/company/CompanyInviteLink.vue";
import CustomDialog from '@/common/CustomDialog.vue'
import { mapState } from 'vuex'

export default {
  name: 'CompanyMembers',
  components: {
    CustomDialog
    // CompanyInviteLink
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    members: [],
    editedIndex: -1,
    editedItem: {
      permissions: []
    },
    defaultItem: {
      permissions: ['admin']
    }
  }),
  computed: {
    ...mapState(['companyMembers']),
    headers () {
      return [
        {
          text: this.$t('Pages.Company.name'),
          value: 'full_name'
        },
        // { text: this.$t('Pages.Company.permissions'), value: 'permissions', sortable: false },
        { text: '', value: 'actions', sortable: false }
      ]
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },

  created () {
    // this.initialize()
  },

  methods: {
    // initialize () {
    // },

    editItem (item) {
      this.editedIndex = this.members.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.members.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    viewUserProfile (user) {
      this.$router.push({ name: 'inner-profile', params: { id: user.userId } })
    },

    deleteItemConfirm () {
      this.members.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.members[this.editedIndex], this.editedItem)
      } else {
        this.members.push(this.editedItem)
      }
      this.close()
    }
  }
}
</script>
